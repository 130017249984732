@import "./styles/_variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./assets/fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Segoeui";
  src: local("Segoeui"), url("./assets/fonts/Segoeui/SEGOEUI.TTF") format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"), url("./assets/fonts/Raleway/Raleway-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway-Bold";
  src: local("Raleway-Bold"), url("./assets/fonts/Raleway/Raleway-Bold.ttf") format("truetype");
}

.btnClass {
  width: 259px;
  height: 55px;
  background-color: $base-btn-blue;
}

.btnClassInfo {
  width: 259px;
  height: 55px;
  background-color: $base-white;
}

.btnText {
  cursor: pointer;
  font-family: $base-font-family-primary;
  font-size: $base-font-size-primary;
  color: $base-btn-blue-text-white;
}

.btnTextInfo {
  cursor: pointer;
  font-family: $base-font-family-primary;
  font-size: $base-font-size-primary;
  color: $base-black;
}

button,
button:focus {
  outline: none;
  border: 1px solid transparent;
}

button:active {
  outline: none;
  border: 1px solid grey;
}

.blink_me {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

.form-control {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 4px solid #b8b8b8 !important;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  color: #4e4e4e;
}
.form-floating > label {
  font-family: $base-font-family-primary;
  font-style: normal;
  font-weight: 300;
  // font-size: 20px;

  color: #b8b8b8;
}

input:focus {
  outline: 0;
}

.loader {
  border: 10px solid #ddd;
  border-radius: 50%;
  border-top: 10px solid #48dcca;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Tooltip container */
.tooltipShelter {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltipShelter .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #243656;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 13px;
  font-family: $base-font-family-primary !important;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipShelter:hover .tooltiptext {
  visibility: visible;
}

.tooltipShelter .tooltiptext {
  top: -5px;
  left: 25px;
}

.tooltipShelter .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #243656 transparent transparent;
}
