@import "../styles/_variables.scss";

@media only screen and (max-width: 768px) {
    //About Page
    div {
        .topRightDiv {
            // display: none !important;
            font-size: 12px !important;
            margin-right: 0rem !important;
        }

        .topDivText {
            width: 350px;
        }
        .parentNavDiv {
            padding: 2rem !important;
        }

        .topLeftDiv {
            width: 100%;
            margin-left: 0rem !important;
        }

        .logoImageDiv {
            width: 100%;
            text-align: center;
        }
    }

    .blueDiv {
        display: none;
        background: #9747ff;
        width: 100%;
        z-index: 99;
        height: 20px;
        overflow: hidden;
        position: fixed;
        top: 60px;
    }

    //Home Page

    // .mobileBannerImageDiv {
    //     box-shadow: 0 0 40px #243656;
    // }
    .parentHomeDiv {
        margin-top: 2rem !important;
    }

    .topDivBlackHome {
        display: none !important;
    }

    .headerTextHome {
    }

    .primaryTextDivHome {
        margin-top: 1rem;
        font-size: 16px;
    }

    .secondryTextDivHome {
    }

    .enterText {
        display: none !important;
    }
    .itemsDivHome {
        padding: 20px !important;
        margin-top: 3rem;
    }

    br {
        display: none;
    }

    .btnDivHome {
        margin-bottom: 5rem;
    }
    .btnDivHomeMobile {
        margin-top: 3rem;
        display: block !important;
    }
    .btnText {
    }

    .blurDiv {
        height: 60px !important;
        -webkit-filter: blur(0px) !important;
    }

    .logoClass {
        width: 71px !important;
        height: 32px !important;
    }

    .bannerImageDiv {
        margin: 0px !important;
    }

    .imageBannerClass {
        display: none !important;
    }

    .imageBannerClassMobile {
        width: 100%;
        height: 579px;
    }

    .textTopDivBlackHome {
        left: 1rem !important;
        width: 350px;
    }

    .topDivGradient {
        display: none !important;
    }

    .knowAboutBtn {
        top: 18rem;
    }

    .headerTextHomeBottom {
        font-size: 30px;
    }

    /// shelter form
    .questionActions {
        display: none !important;
    }

    .stepsProgressTextDiv {
        display: none !important;
    }

    .stepsProgressBarDiv {
        display: none !important;
    }

    .questionNumber {
        // display: none !important;
        margin-left: 0.8rem;
    }

    .questionaireParentDiv {
        width: 100% !important;
        margin-bottom: 16rem;
    }

    .questionPrimaryText {
        font-size: 30px !important;
        height: auto;
    }
    .mlShelter {
        margin-left: 1rem;
    }

    .answerOption {
        margin-left: 2rem;
        margin-top: 0rem;
    }

    .dropdown{
        margin: 1rem!important;
    }

    //footer fixed
    .mobileFooter {
        display: block;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 94px;
        background-color: #f5f5f5;
        color: white;
        text-align: center;
    }

    .skipQuestionDiv {
        margin: 0rem;
    }

    .radio-toolbar label:hover {
        background-color: #f5f5f5;
    }

    .cardArea {
        margin-bottom: 0.5rem;
    }

    .loaderText {
        margin: 2rem;
    }

    .sidebar-container {
        width: 250px;
    }

    //shelter list item
    .resultBox {
        width: 100%;
    }

    .upperTextResult {
        margin: 1rem;
    }

    .modalSize {
        width: auto !important;
    }

    .shelterImgLarge {
        width: 300px;
        height: 300px;
    }

    .mobileShelterTitle {
        display: block !important;
    }

    .nonMobileShelterTitle {
        display: none !important;
    }
}

@media only screen and (min-width: 1024px) {
    .mobileBannerImageDiv {
        display: none !important;
        box-shadow: 0 0 40px #243656;
    }

    .mobileFooter {
        display: none !important;
    }

    .mobileShelterTitle {
        display: none !important;
    }
}
