@import "../../styles/_variables.scss";

.shelterForm {
    // position: relative;
    // top: 8rem;
    // width: 100%;
    margin-top: 8rem;
}

.shelterLoader {
    position: relative;
    top: 20rem;
    width: 100%;
}
.questionaireParentDiv {
    width: 50%;
}

.stepsProgressTextDiv {
    font-family: $base-font-family-primary;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #4e4e4e;
}

.stepsProgressBarDiv {
    padding-left: 1.5rem;
    position: relative;
}

.questionPrimaryText {
    font-family: $base-font-family-header;
    font-weight: 700;
    font-size: 40px;
    color: #243656;
    display: block;
    height: 100px;
}

.questionSecondaryText {
    font-family: $base-font-family-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #4e4e4e;
    // margin-left: 2rem;
    margin-left: 4rem !important;
}

.answerOption {
    // margin-top: 2rem;
    height: 150px;
}

.questionActions {
    margin-left: 3rem !important;
}

.ellipseClass {
    width: 30%;
}

.rectangleClass {
    position: absolute;
    top: 0;
    left: 0;
}

.prevQuestionDiv {
    height: 55px;
    width: 55px;
    background-color: $base-btn-blue;
    color: $base-white;
}

.continueQuestionDiv {
    background: #b8b8b8;
    width: 259px;
    height: 55px;
    font-family: $base-font-family-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    color: #4e4e4e;
}
.labelRadio {
    cursor: pointer;
}

.mlShelter {
    margin-left: 2rem;
    text-align: left;
}
.skipQuestionDiv {
    // background: #243656;
    background: #ffffff;
    border: 1px solid #243656;
    width: 220px;
    height: 55px;
    font-family: $base-font-family-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    color: #243656;
}

.skipQuestionDiv:active {
    background-color: $base-btn-blue;
    color: $base-btn-blue-text-white;
}
// .radiobtn {
//     position: relative;
// }

// .labelRadio {
//     position: absolute;
//     width: 141px;
//     height: 33px;
//     left: 491px;
//     // top: 505px;
//     background: #f5f5f5;
//     border-radius: 8px;
// }

// radio btn

.radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.radio-toolbar label {
    display: inline-block;
    background-color: #ddd;
    padding: 10px 20px;

    font-family: $base-font-family-primary;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #000000;
    background: #f5f5f5;
    border-radius: 8px;
}

.radio-toolbar label:hover {
    background-color: #48dcca;
}

.radio-toolbar input[type="radio"]:focus + label {
    border: 2px dashed #444;
}

.radio-toolbar .checkedRadio {
    background-color: #48dcca;
}

.loaderText {
    font-family: $base-font-family-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #4e4e4e;
    margin: 2rem;
}

#ageDropdown{
    display: inline-block;
    padding: 10px 20px;
    font-family: "Raleway";
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #000000;
    background: #f5f5f5;
    border-radius: 8px;
}
.btn-check:focus+.btn-success, .btn-success:focus{
    box-shadow: 0 0 0 0.05rem $base-btn-blue !important;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: $base-btn-blue !important;
    color: $base-white !important;
}

.dropdown-menu {
    height: 200px;
    overflow-y: scroll;
  }
  .dropdown-toggle::after{
    
      margin-left: 1.255em !important;
  }