@import "../../styles/_variables.scss";
.shelterList {
    width: 100%;
    padding-left: 0;
    margin-top: 2rem;
    margin-bottom: 5rem;
    flex-flow: row wrap;
}

.shelterList::before,
.shelterList::after {
    content: "";
    width: 100%;
    order: 1;
}

.resultBox {
    margin-top: 10rem;
    width: 1400px;
}

.cardArea {
    background: #f7f7f7;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);
    width: 360px;
    height: 152px;
    margin: 2rem;
    padding: 0.5rem;
    cursor: pointer;
}

.shelterTitle {
    font-family: $base-font-family-header;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    color: #000000;
}

.shlterAddress {
    font-family: $base-font-family-primary;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #000000;
}

.startOverDiv {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.noShelterText {
    font-family: $base-font-family-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #4e4e4e;
}

.goodNewstext {
    font-family: $base-font-family-header;
    font-size: 40px;
    color: #243656;
    text-align: left;
}

.goodNewsSubtext {
    font-family: $base-font-family-primary;
    font-size: 20px;
    color: #4e4e4e;
    text-align: left;
}

.shelterImg {
    width: 128px;
    height: 128px;
}
.startOverFix {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-color: #d9d9d9;
    color: white;
    text-align: center;
}

.startOverBtn {
    // background: #243656;
    background: #243656;
    width: 259px;
    height: 55px;
    font-family: $base-font-family-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    color: $base-white;
}
.shelterDetailsDiv {
    width: 100%;
    height: 100%;
}

.lowerFont {
    font-size: 16px !important;
}
.blurWrapper {
    -webkit-filter: blur(8px);
    -ms-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    filter: blur(8px);
}

.shelterSubtitleText {
    font-family: $base-font-family-header;
    font-weight: 700;
    font-size: 12px;
    color: #618bd5;
}

.recommendedClass {
    height: 100%;
    color: #243656;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size: 13px;
    font-family: $base-font-family-primary !important;
    position: relative;
}

.recommendedText {
    position: absolute;
    bottom: 0;
    right: 0;
}
