@import "../../../styles/_variables.scss";

.modal-content {
    background: #f7f7f7;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);
    // width: 521px !important;
}
.modalSize {
    width: 400px !important;
}
.modal-body {
    height: auto;
}
.modalParent {
    margin-top: 0.5rem;
    margin-left: 1rem;
}
.shelterTitleModal {
    font-family: $base-font-family-header;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    color: #000000;
}

.shelterImgLarge {
    width: 340px;
    height: 300px;
}

.headerName {
    width: 100%;
}
