@import "../../styles/_variables.scss";
.navParent {
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 9;
}

.parentNavDiv {
    // background: #151515;
    width: 100%;
    height: 40px;
    color: $base-white;
    // position: absolute;
    // left: 0;
    // top: 0;
    z-index: 100;

    overflow: hidden;
    position: fixed;
    top: 0;
}

.blurDiv {
    background: #151515;
    opacity: 0.91;
    filter: blur(1px);
    // position: absolute;
    width: 100%;
    height: 70px;
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    z-index: 99;

    overflow: hidden;
    position: fixed;
    top: 0;
}

.blueDiv {
    display: none;
}

.topLeftDiv {
    width: 8%;
    margin-left: 2rem;
}

.navBarIconClass {
    cursor: pointer;
}

.logoClass {
    width: 109px;
    height: 48px;
}

.topRightDiv {
    margin-right: 2rem;
}
