$base-black: #000000;
$base-white: #ffffff;
$base-btn-blue: #243656;
$base-btn-blue-text-white: #fefefe;

$base-btn-color-grey: #d9d9d9;

$base-font-size-header: 40px;
$base-font-size-primary: 20px;
$base-font-size-secondry: 12px;

$base-font-weight-header: 700;
$base-font-weight-primary: 400;
$base-font-weight-secondary: 200;

$base-font-family-header: "Raleway-Bold";
$base-font-family-primary: "Raleway";
