@import "../../styles/_variables.scss";

.topDivBlackHome {
    // height: 23vh;
    height: 488px;
    background-color: $base-black;
    color: $base-white;
}
// .bannerImageDiv {
//     position: absolute;
//     left: 0px;
//     top: 0;
//     z-index: 1;
//     width: 100%;
//     height: 488px;
//     overflow: hidden;
// }

.imageBannerClass {
    width: 100%;
}

.itemsDiv {
    text-align: left;
}

.headerTextHome {
    font-family: $base-font-family-header;
    font-size: $base-font-size-header;
    color: $base-btn-blue;
    font-weight: $base-font-weight-header;
    text-align: left;
}

.headerTextHomeBottom {
    font-family: $base-font-family-header;
    font-size: $base-font-size-header;
    color: $base-btn-blue;
    font-weight: $base-font-weight-header;
    text-align: left;
}

.primaryTextDivHome {
    font-family: $base-font-family-primary;
    font-size: $base-font-size-primary;

    color: #4e4e4e;
    font-weight: $base-font-weight-primary;
    text-align: left;
}

.turnDownIcon {
    transform: rotate(90deg);
}

.btnDivHomeMobile {
    display: none;
}

// .textTopDivBlackHome {
//     position: relative;
//     top: 10rem;
//     left: 10rem;
// }
.topDivText {
    color: $base-white !important;
    position: absolute;
    font-weight: 800;
    font-size: 40px;
    color: #f9f9f9;
    width: 450px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 7;
}

.knowAboutBtn {
    position: absolute;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #243656;
    top: 12rem;
    left: 0;
    z-index: 7;
}

.secondryTextDivHome {
    font-family: $base-font-family-primary;
    font-size: $base-font-size-secondry;
    color: $base-btn-blue !important;
    font-weight: $base-font-weight-primary;
}

.warningDisclosureText {
    font-family: $base-font-family-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: left;
    margin-top: 1rem;
    color: #4e4e4e;
}
.warningIcon {
    margin-right: 1rem;
}

// new design classes
.parentHomeDiv {
    margin-top: 10rem;
}
.letsBeginDivHome {
}

.bannerImageDiv {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: 7rem;
    box-shadow: 0 0 100px #243656;
}

.bannerImageDiv .topDivGradient {
    position: absolute;
    width: 100%;
    height: 488px;
    left: 0px;
    bottom: 0;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(20, 30, 47, 0.552083) 86.46%, #243656 100%);
}

.bannerImageDiv .topDivGradientTwo {
    position: absolute;
    width: 100%;
    height: 488px;
    left: 0;
    top: 0;
    z-index: 2;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(20, 30, 47, 0.552083) 86.46%, #243656 100%);
}

.bannerImageDiv .topDivGradientThree {
    position: absolute;
    width: 100%;
    height: 488px;
    left: 0;
    top: 0;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(20, 30, 47, 0.552083) 86.46%, #243656 100%);
}

.textTopDivBlackHome {
    position: absolute;
    top: 10rem;
    left: 10rem;
}
