@import "../../styles/_variables.scss";

.sidebar-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    background: #151515;
    opacity: 0.97;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 450px;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(-100%);
    }
}

.bottom-footer {
    border-top: 1px solid;
    color: $base-white;
    text-align: left;
}

.bottom-side-bar-text1 {
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    color: #ffffff;
}

.bottom-side-bar-weight {
    font-weight: 200;
}

.navigaiton-items {
    font-family: $base-font-family-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-top: 8rem;
    text-align: left;
    margin-left: 2rem;
    color: #ffffff;
}

.bold-item {
    font-weight: 700;
}
